import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Button,
  Container,
  Segment,
  List,
  Input,
  Grid,
  Divider,
  Responsive,
  Icon,
} from "semantic-ui-react";
import { start } from "repl";
import * as _ from "lodash";
import moment from "moment";
import Plot from "react-plotly.js";

function App() {
  const [started, setStarted] = useState(false);
  const [buttonText, setButtonText] = useState("Start");
  const [timer, setTimer] = useState("00:00:00");
  const [times, setTimes]: [number[], any] = useState([]);

  const [clockStartTime, setClockStartTime] = useState(moment());
  const [intervalID, setIntervalID] = useState(setTimeout(() => {}, 0));

  useEffect(() => {
    setTimes(JSON.parse(localStorage.getItem("times")||"[]"));
  },[]);

  const getLast3attempts = () => {
    return _.takeRight(times, 3).map((time, i) => {
      return <List.Item key={i}>{getFormattedTime(time)}</List.Item>;
    });
  };

  const getFormattedTime = (seconds: number): string => {
    return moment.utc(seconds * 1000).format("HH:mm:ss");
  };

  return (
    <div className="App">
      <Grid divided stackable>
        <Grid.Row>
          <Grid.Column tablet={16} computer={4} ></Grid.Column>
          <Grid.Column tablet={16} computer={8} >
            <Container>
              <Divider/>
              <Segment>
                A nice little stopwatch and counter for training.
              </Segment>
              <Segment>
                Total number of attempts - {times.length}.
                <br />
                Last 3 attempts -<List ordered>{getLast3attempts()}</List>
                <br />
                Best time - {getFormattedTime(_.max(times) || 0)}
                <br />
                Total time - {getFormattedTime(_.sum(times))}
                <br/>
                <br/>
                <Button basic size="mini" onClick={()=>{
                  if(window.confirm("are you sure? this will delete all your stored data...")){
                    localStorage.clear();
                    setTimes([]);
                  }
                }}>Clear Data</Button>
              </Segment>
              <Button
                color="olive"
                content={buttonText}
                onClick={() => {
                  setButtonText(started ? "Start" : "Stop");
                  if (!started) {
                    setTimer("00:00:00");
                    const startTime = moment();
                    setClockStartTime(startTime);
                    const id = setInterval(() => {
                      const duration = moment.utc(moment().diff(startTime));
                      const elapsedTime = duration.format("mm:ss:SS");
                      setTimer(`${elapsedTime}`);
                    }, 10);
                    setIntervalID(id);
                  } else {
                    clearInterval(intervalID);
                  }
                  setStarted(!started);
                }}
              ></Button>
              <Segment>
                <Input
                  value={timer}
                  type="text"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (started) {
                      return;
                    }
                    setTimer(e.target.value);
                  }}
                ></Input>
                <Button
                  size="mini"
                  color="olive"
                  onClick={() => {
                    const newTimes = times.concat(
                      moment(timer, "mm:ss:SS", true).minutes() * 60 +
                        moment(timer, "mm:ss:SS", true).seconds()
                    );
                    if (!_.isNaN(_.last(newTimes))) {
                      setTimes(newTimes);
                      localStorage.setItem("times", JSON.stringify(newTimes));
                    }
                  }}
                  icon
                >
                  <Icon name="arrow down"></Icon>
                  Note Down
                </Button>
              </Segment>
              <Segment hidden={times.length < 3}>
                <Plot
                  data={[
                    { x: times.map((a, i) => i), y: times, type: "scatter" },
                  ]}
                  layout={{ autosize: true, title: "Your times", height:350 }}
                ></Plot>
              </Segment>
            </Container>
            <Divider/>
          </Grid.Column>
          <Grid.Column tablet={16} computer={4}></Grid.Column>
        </Grid.Row>
      </Grid>
      <Responsive as={Divider} maxWidth="900"/>
    </div>
  );
}

export default App;
